// import SubmissionWelcomeModule from './submission-welcome-modal';
import SubmissionInstructionModule from './submission-instruction-modal'

import ProjectCancelModalModule from './project-cancel-modal'
import ProjectConvertTypeModalModule from './project-convert-type-modal'
import ProjectReviewModalModule from './project-review-modal'
import WithdrawalModalModule from './withdrawal-modal'

import ClientProfileViewModalModule from './client-profile-view-modal'
import PersonaSurveyModalModule from './persona-survey-modal'
import ProjectCompletedReminderModalModule from './project-completed-reminder-modal'
import EstimationTaskReminderModalModule from './estimation-task-reminder-modal'
import HiredReminderModalModule from './hired-reminder-modal/hired-reminder-modal.module'
import EarningsCertificateModalModule from './earnings-certificate-modal'
import PartnerDetailsModalModule from './partner-details-modal/partner-details-modal.module'
import AcceptTOSModalModule from './accept-tos-modal'
import EngagementClaimModalModule from './engagement-claim-modal'

const ModalsModule = angular.module('cdbl.modals', [
  // SubmissionWelcomeModule,
  SubmissionInstructionModule,
  ProjectCancelModalModule,
  ProjectConvertTypeModalModule,
  ProjectReviewModalModule,
  WithdrawalModalModule,
  ClientProfileViewModalModule,
  PersonaSurveyModalModule,
  ProjectCompletedReminderModalModule,
  EstimationTaskReminderModalModule,
  HiredReminderModalModule,
  EarningsCertificateModalModule,
  PartnerDetailsModalModule,
  AcceptTOSModalModule,
  EngagementClaimModalModule
])
  .name

export default ModalsModule
