(function () {
  'use strict';

  function commentsForm($rootScope, Configuration, $http, Auth, Storage, $timeout, Modal, UserService, CommentService, ProjectService) {
    var link = function ($scope, elem, attrs) {
      $scope.isProcessing = false;
      $scope.$watchGroup(['task.state', 'task.actions'], function() {
        broadcastFormDimensions();
      });

      $scope.$watch('comment.text', function (text) {
        if ($scope.comment && $scope.task && Auth.me()) {
          Storage.set(Auth.me().id + '|' + $scope.task.id, text);
        }
      });

      let commentFromStorage = Storage.get(Auth.me().id + '|' + $scope.task.id);
      setTimeout(function () {
        $(elem).find('textarea')[0].value = commentFromStorage || '';
        $(elem).find('textarea').eq(0).trigger('change');
      }, 100);

      broadcastFormDimensions();

      $scope.postComment = function (taskId, text, isContractorsOnly, force) {
        if ($scope.isProcessing) {
          return // prevent double posing by CTRL + ENTER or double clicking
        }

        if (!force && UserService.isClient()) {
          if (typeof text !== 'string') {
            return;
          }

          const sensitiveKeywords = [
            'password',
            'passwords',
            'username',
            'sign in',
            'sign-in'
          ];

          let matchString = '';
          for (const [index, keyword] of sensitiveKeywords.entries()) {
            matchString += `\\b(${keyword})\\b|`;
            matchString += `\\b(${keyword}:)\\b|`;
            matchString += `\\b(${keyword}\\|)\\b|`;
            matchString += `\\b(${keyword}=)\\b`;
            matchString += `${index < sensitiveKeywords.length - 1 ? '|' : ''}`;
          }
          const re = RegExp(matchString, 'gi');
          const match = text.match(re);
          const isMatch = Boolean(match) && Boolean(match.length);

          // show modal if there is a match
          if (isMatch) {
            if (Storage.get(`sensitiveKeywordsModalShown:${taskId}`)) {
              // console.log('postComment > sensitiveKeywords > modal already shown', Storage.get(`sensitiveKeywordsModalShown:${taskId}`));
            } else {
              const postCommentAnyway = futureIgnore => {
                Modal.close();
                Storage.set(`sensitiveKeywordsModalShown:${taskId}`, futureIgnore);
                $scope.postComment(taskId, text, isContractorsOnly, true);
              };

              const modifyComment = futureIgnore => {
                Modal.close();
                Storage.set(`sensitiveKeywordsModalShown:${taskId}`, futureIgnore);
                $('marked-textarea textarea').focus();
              };

              const getHighlightedText = text => {
                let highlightedText = text.replace(re, match => {
                  return '<span class="highlight">' + match + '</span>';
                });
                return highlightedText;
              };

              $timeout(function () {
                // console.log('postComment > sensitiveKeywords > open modal', match);
                Modal.open(
                  'views/modals/comment-sensitive-keywords-found.html',
                  {
                    originalText: text,
                    highlightedText: getHighlightedText(text),
                    postCommentAnyway: postCommentAnyway,
                    modifyComment: modifyComment,
                    futureIgnore: false
                  },
                  {
                    closeButton: true
                  }
                );

                $timeout(() => {
                  $('#modalSensitiveKeywords .cdbl-button').first().focus();
                }, 100);
              });

              // prevent posting
              return;
            }
          }
        }

        // Create a comment instance that also has activity verb to fill the gap between comments and activities (comments returned by activity endpoint has verb that is crucial to determine template)
        // This could be done in much much better way. I patched to the degree it works at least.

        var commentInstance = {
          text: text,
          // publishedAt: new Date().getTime() / 1000, // measured in s, details in APIB,
          // user: Auth.me(),
          // userGenerated: true,
          verb: 'create_comment',
          // shake: true,
          isContractorsOnly: isContractorsOnly
        };

        if (attrs.private === 'true') {
          commentInstance.private = true;
        }

        $scope.isProcessing = true
        CommentService.postComment(taskId, text, isContractorsOnly)
          .then(response => {
            Object.assign(commentInstance, response)
            commentInstance.shake = false;

            if (typeof $scope.onAddNewCallback === 'function') {
              $timeout(() => {
                $scope.onAddNewCallback({
                  comment: commentInstance
                });
              });
            }

            console.log('postComment > ', $scope.task.subscribedByCurrentUser, response);
            if (!$scope.task.subscribedByCurrentUser) {
              UserService.userProjectSubscribe($scope.task);
            }

            if (!$scope.refactored) {
              $timeout(function () {
                var scrollContainer = angular.element(document.querySelector('div[create-overflow-comments]'));
                scrollContainer.scrollTop(scrollContainer[0].scrollHeight);
              });
            }

            $scope.comment.text = '';
            $scope.isProcessing = false;
          }, error => {
            $scope.isProcessing = false;
            Modal.open('views/modals/comment_error.html', $rootScope, {
              closeButton: false
            });
            console.log(error);
          })
      };

      $scope.determineButtonText = function (isContractorsOnly) {
        if ($scope.isProcessing) {
          return 'SENDING...';
        }

        if (UserService.isClient()) {
          return 'SEND';
        }

        return isContractorsOnly ? 'SEND TO EXPERTS' : 'SEND';
      };

      $scope.enterToSend = Storage.get('enterToSend') === true;

      $scope.saveEnterToSend = function (enterToSend) {
        $scope.enterToSend = enterToSend;
        Storage.set('enterToSend', enterToSend);
      };

      $scope.taskNotInProgress = taskNotInProgress;

      function broadcastFormDimensions() {
        $scope.allowedToComment = (taskNotInProgress() || (UserService.isClient() || UserService.isExpert())) &&
        (!ProjectService.project.eligibleForClaiming || (ProjectService.canClaimDeleteEngagement || ProjectService.isCurrentExpertClaimer));

        var height = $scope.allowedToComment ? elem.outerHeight() : 0;
        var marginTop = $scope.allowedToComment ? parseInt(elem.css('margin-top'), 10) : 0;

        $scope.$broadcast('commentsFormDimensions', {
          height: height,
          marginTop: marginTop
        });
      }

      $scope.allowPrivateComments = function () {
        return (UserService.isExpert() &&
          $scope.task.state !== 'canceled');
      };

      function taskNotInProgress() {
        return $scope.task.state !== 'paid' && $scope.task.state !== 'completed' &&
          $scope.task.state !== 'canceled';
      }
    };

    return {
      link: link,
      restrict: 'E',
      replace: true,
      scope: {
        refactored: '=',
        task: '=',
        onAddNewCallback: '&'
      },
      templateUrl: require('../../views/comments/comments_form.html')
    };
  }

  app.directive('commentsForm', ['$rootScope', 'Configuration', '$http', 'Auth', 'Storage', '$timeout', 'Modal', 'UserService', 'CommentService', 'ProjectService', commentsForm]);
})();
