import templateUrl from './engagement-claim-modal.html'
import './engagement-claim-modal.scss'

const EngagementClaimModalComponent = {
  bindings: {
    projectId: '<',
    mode: '<'
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class EngagementClaimModal {
    constructor (Configuration, ProjectClaimsService, ProjectService, UserService) {
      'ngInject'
      this.Configuration = Configuration
      this.ProjectService = ProjectService
      this.ProjectClaimsService = ProjectClaimsService
      this.UserService = UserService
      this.loading = false
      this.error = null
    }

    $onInit () {
    }

    handleAction () {
      this.loading = true
      this.error = null

      const action = this.mode === 'claim'
        ? this.ProjectClaimsService.claimProject(this.projectId)
        : this.ProjectClaimsService.unclaimProject(this.projectId)

      action
        .then(response => {
          this.modalCtrl.resolve(response)
          Object.assign(this.ProjectService.project, response.project)
          delete response.expert.id
          Object.assign(this.UserService.user, response.expert)
        })
        .catch(err => {
          this.error = err.message || 'An error occurred. Please try again.'
          console.error(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}

export default EngagementClaimModalComponent
