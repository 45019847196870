import templateUrl from './sidebar-widget.html'
import './sidebar-widget.scss'

const ProjectClaimsSidebarWidgetComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectClaimsSidebarWidgetComponent {
    constructor (PusherService, ProjectClaimsService, ProjectService, UserService, $timeout, ModalService, DataInterceptor) {
      'ngInject'
      this._identify = 'ProjectClaimsSidebarWidgetComponent'
      this.PusherService = PusherService
      this.isSubscribed = false
      this.ProjectClaimsService = ProjectClaimsService
      this.ProjectService = ProjectService
      this.UserService = UserService
      this.$timeout = $timeout
      this.ModalService = ModalService
      this.activeChannels = new Set()
      this.DataInterceptor = DataInterceptor
      /*
      Sometimes the socket events are delayed and the user just performed a claim / unclaim action,
      so we need to ignore the socket event and prevent the reload
      ~_~
      */
      this.currentExpertJustPerformedAction = false
    }


    $onInit () {
      this.ProjectClaimsService.init()

      const currentUserId = this.UserService.user.id
      const projectId = this.ProjectService.project.id

      if (!projectId) {
        throw new Error('Claim project id is not known, use component only in areas that ProjectService.project exists')
      }

      this.channelNames = {
        claimers: `private-expert-project-${projectId}-claimers`,
        observers: `private-expert-project-${projectId}-observers`,
        expert: `private-expert-${currentUserId}`
      }

      this.setupInitialSubscriptions()
    }

    setupInitialSubscriptions () {
      const project = this.ProjectService.project
      // If current user claimed (determined by deleteEngagementClaim action)
      if (project.actions?.deleteEngagementClaim) {
        this.subscribeToChannels([this.channelNames.claimers, this.channelNames.expert])
      } else {
        // Otherwise subscribe to observers channel
        this.subscribeToChannels([this.channelNames.observers])
      }
    }

    handleClaimersEvents (channelName, eventName, data) {
      console.log('🚀 ~ Claimers channel event:', channelName, eventName, data)

      if (this.ProjectService.project?.id === data.project.id) {
        switch (eventName) {
        case 'project-engagement-claimed':
        case 'project-engagement-canceled':
          const expert = this.DataInterceptor.convertCase(data.expert, 'toCamelCase')
          const project = this.DataInterceptor.convertCase(data.project, 'toCamelCase')

          if (expert.id === this.UserService.user.id && this.ProjectService.project.actions?.deleteEngagementClaim && !this.currentExpertJustPerformedAction) {
            window.location.reload()
          } else {
            Object.assign(this.ProjectService.project, project)
            // Object.assign(this.UserService.user, expert)
          }
          break
        }
        this.$timeout()
      }
    }

    handleObserversEvents (channelName, eventName, data) {
      console.log('🚀 ~ Observers channel event:', channelName, eventName, data)

      if (this.ProjectService.project?.id === data.project.id) {
        switch (eventName) {
        case 'project-engagement-claimed':
        case 'project-engagement-canceled':
          const expert = this.DataInterceptor.convertCase(data.expert, 'toCamelCase')
          const project = this.DataInterceptor.convertCase(data.project, 'toCamelCase')

          if (expert.id === this.UserService.user.id && !this.currentExpertJustPerformedAction) {
            window.location.reload()
          } else {
            Object.assign(this.ProjectService.project, project)
            // Object.assign(this.UserService.user, expert)
          }
          break
        }
        this.$timeout()
      }
    }

    handleExpertEvents (channelName, eventName, data) {
      console.log('🚀 ~ Expert channel event:', channelName, eventName, data)

      if (this.ProjectService.project?.id === data.project.id) {
        switch (eventName) {
        case 'project-engagement-canceled':
          Object.assign(this.ProjectService.project, this.DataInterceptor.convertCase(data.project, 'toCamelCase'))
            // Switch back to observers when claim is canceled
          this.subscribeToChannels([this.channelNames.observers])
          break
        }
        this.$timeout()
      }
    }

    subscribeToChannels (channels) {
      // Unsubscribe from any existing channels first
      this.unsubscribeFromAll()

      // Subscribe to new channels with their specific handlers
      channels.forEach(channelName => {
        if (!this.activeChannels.has(channelName)) {
          switch (true) {
          case channelName === this.channelNames.claimers:
            this.PusherService.bindEvent(channelName, 'project-engagement-claimed', (data) => {
              this.handleClaimersEvents(channelName, 'project-engagement-claimed', data)
            })
            this.PusherService.bindEvent(channelName, 'project-engagement-canceled', (data) => {
              this.handleClaimersEvents(channelName, 'project-engagement-canceled', data)
            })
            break

          case channelName === this.channelNames.observers:
            this.PusherService.bindEvent(channelName, 'project-engagement-claimed', (data) => {
              this.handleObserversEvents(channelName, 'project-engagement-claimed', data)
            })
            this.PusherService.bindEvent(channelName, 'project-engagement-canceled', (data) => {
              this.handleObserversEvents(channelName, 'project-engagement-canceled', data)
            })
            break

          case channelName === this.channelNames.expert:
            this.PusherService.bindEvent(channelName, 'project-engagement-canceled', (data) => {
              this.handleExpertEvents(channelName, 'project-engagement-canceled', data)
            })
            break
          }
          this.activeChannels.add(channelName)
        }
      })

      this.isSubscribed = true
    }

    unsubscribeFromAll () {
      this.activeChannels.forEach(channelName => {
        switch (channelName) {
        case this.channelNames.claimers:
          this.PusherService.unbindEvent(channelName, 'project-engagement-claimed')
          this.PusherService.unbindEvent(channelName, 'project-engagement-canceled')
          break
        case this.channelNames.observers:
          this.PusherService.unbindEvent(channelName, 'project-engagement-claimed')
          break
        case this.channelNames.expert:
          this.PusherService.unbindEvent(channelName, 'project-engagement-canceled')
          break
        }
        this.PusherService.unsubscribe(channelName)
      })
      this.activeChannels.clear()
      this.isSubscribed = false
    }

    $onDestroy () {
      console.log('🚀 ~ ProjectClaimsSidebarWidgetComponent ~ $onDestroy')
      this.unsubscribeFromAll()
    }

    openClaimModal (projectId, mode) {
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: true,
        class: 'engagement-claim-modal cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
        template: `
          <cdbl-engagement-claim-modal
            project-id="$ctrl.modal.projectId"
            mode="$ctrl.modal.mode">
          </cdbl-engagement-claim-modal>`,
        projectId: projectId,
        mode: mode
      }).then(response => {
        if (mode === 'claim') {
          // When claiming, subscribe to claimers and expert channels
          this.currentExpertJustPerformedAction = true
          this.subscribeToChannels([this.channelNames.claimers, this.channelNames.expert])
        } else if (mode === 'unclaim') {
          // When unclaiming, subscribe to observers channel
          this.currentExpertJustPerformedAction = true
          this.subscribeToChannels([this.channelNames.observers])
        }
      })
    }

    claimProject (projectId) {
      this.openClaimModal(projectId, 'claim')
    }

    unclaimProject (projectId) {
      this.openClaimModal(projectId, 'unclaim')
    }
  }
}

export default ProjectClaimsSidebarWidgetComponent
